.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.header-blue {
  background: #4F52FF;
  /* ff 3.6+ */
  background:-moz-linear-gradient(180deg, #4042CD 0%, #484BE9 25%, #4B4EF4 45%, #4B4EF4 55%, #484BE9 75%, #4042CD 100%); 

  /* safari 5.1+,chrome 10+ */
  background:-webkit-linear-gradient(180deg, #4042CD 0%, #484BE9 25%, #4B4EF4 45%, #4B4EF4 55%, #484BE9 75%, #4042CD 100%);

  /* opera 11.10+ */
  background:-o-linear-gradient(180deg, #4042CD 0%, #484BE9 25%, #4B4EF4 45%, #4B4EF4 55%, #484BE9 75%, #4042CD 100%);

  /* ie 6-9 */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4042CD', endColorstr='#4042CD', GradientType=1 );

  /* ie 10+ */
  background:-ms-linear-gradient(180deg, #4042CD 0%, #484BE9 25%, #4B4EF4 45%, #4B4EF4 55%, #484BE9 75%, #4042CD 100%);

  /* global 94%+ browsers support */
  background:linear-gradient(180deg, #4042CD 0%, #484BE9 25%, #4B4EF4 45%, #4B4EF4 55%, #484BE9 75%, #4042CD 100%);
}
